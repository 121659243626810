import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { array as arrayYup, object as objectYup, string as stringYup } from 'yup';
import { useHistory } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../config/constants';
import { ServiceClassData, TeacherType, TrainingGroupItemType } from '../../../types/service-class';
import { ServiceClassContext } from '../service-class';
import ServiceField from '../components/fields/service';
import SimpleInput from '../../../components/fields/simple-input';
import DateField from '../components/fields/date';
import PupilInGroupField from '../components/fields/pupil-in-group';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import serviceClassApi from '../../../lib/api/service-class';
import TeachersField from '../components/fields/teachers';
import history from '../../../history';
import { generateLink, getEmptyFunctionToOverride } from '../../../lib/utils';
import {
  childrenAssociation,
  docDate,
  minCapacityValidation,
  serviceClassName,
  validationCheckDate,
} from '../../../lib/utils/validation';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import {
  editPeriodDataToTransformRequestData,
  extendedPeriods,
  maxCommentLength,
  scheduleDataCreateService,
} from '../../../lib/utils/service-class';
import EditPeriodSchedule, { PeriodType } from '../../../components/schedule/period';
import AddressField from '../components/fields/address';
import TrainingGroupField from '../components/fields/training-group';
import SavePanel from '../../../components/save-panel';
import { getStartDate } from '../../../lib/utils/date';

type Props = {
  showSearchServiceBtn: boolean;
};

const ServiceClassDonmCreate = ({ showSearchServiceBtn }: Props) => {
  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [scheduleIsError, setScheduleIsError] = useState(false);
  const [currentTrainingGroup, setCurrentTrainingGroup] = useState<TrainingGroupItemType | null>(null);
  const { serviceClassData, startDateSchedulePeriod, endDateSchedulePeriod } = useContext(ServiceClassContext);

  const [schedule, setSchedule] = useState<PeriodType[]>(scheduleDataCreateService('days'));

  const historyRouter = useHistory();

  const submitForm = async (values: any) => {
    const v = {
      ...values,
      schedule: {
        educationTypeId: serviceClassData.educationTypeId,
        list: editPeriodDataToTransformRequestData(values.schedule),
      },
    };

    try {
      values.teacher.list = values.teachers || [];
      const id = await serviceClassApi.createServiceClass(v);

      history.push(
        generateLink(routes.serviceClass, {
          id,
        })
      );
    } catch {}
    setLoadBtn(false);
  };

  const initialValues: ServiceClassData = {
    ...serviceClassData,
    trainStartDate: '',
    trainEndDate: '',
  };

  const initialErrors = useInitialErrors(
    serviceClassData,
    getValidationSchema(currentTrainingGroup, initialValues.capacity)
  );

  const handleCancel = () => {
    history.push(routes.serviceClasses);
  };

  const handleCreate = () => {
    setLoadBtn(true);
    setScheduleSubmit(true);
  };

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => historyRouter.push(generateLink(routes.serviceClasses, {}))}
        title="Новая группа"
        dataTest="serviceClassDonmCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Группы обучения',
            link: routes.serviceClasses,
          },
          {
            label: 'Группа обучения',
          },
        ]}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={() => getValidationSchema(currentTrainingGroup, initialValues.capacity)}
        initialErrors={initialErrors}
        // Из-за компонента расписания пришлось вынести onSubmit.
        onSubmit={getEmptyFunctionToOverride()}
      >
        {(formikProps: FormikProps<ServiceClassData>) => {
          const { isValid, values } = formikProps;

          return (
            <form>
              <Push size={12} />
              <Panel title={() => <>Сведения о группе</>}>
                <div className="container">
                  <div className="table-data">
                    <ServiceField
                      name="service"
                      label="Детское объединение "
                      showSearchServiceBtn={showSearchServiceBtn}
                      editMode
                      required
                      educationType={serviceClassData.educationTypeId}
                      isNewServiceClass
                    />

                    <SimpleInput
                      name="name"
                      editMode
                      required
                      label="Наименование группы"
                      placeholder="Введите..."
                    />

                    {values.typeOfAttendanceId === 1 && (
                      <AddressField
                        disabled={!values.serviceId}
                        placeholder="Выберите..."
                        name="address"
                        editMode
                        required
                        label="Адрес"
                        explainText={!values.serviceId ? 'Сначала выберите детское объединение' : undefined}
                      />
                    )}

                    <TrainingGroupField
                      name="trainingGroup"
                      defaultValue="—"
                      editMode
                      required
                      setCurrentTrainingGroup={setCurrentTrainingGroup}
                    />

                    <DateField
                      label="Даты занятий"
                      name="train"
                      editMode
                      required
                      isNewServiceClass
                      currentTrainingGroup={currentTrainingGroup}
                    />

                    <PupilInGroupField
                      name="included"
                      label="Человек в группе"
                      editMode
                      isNewServiceClass
                      required
                    />

                    <SimpleTextArea
                      name="description"
                      label="Комментарий"
                      editMode
                      placeholder="Введите..."
                      maxLength={maxCommentLength}
                    />
                  </div>
                </div>
              </Panel>
              <Push size={12} />
              <EditPeriodSchedule
                key={`${String(startDateSchedulePeriod)} ${String(endDateSchedulePeriod)}`}
                dateStartProps={startDateSchedulePeriod}
                dateEndProps={endDateSchedulePeriod}
                title="Время проведения занятий"
                submit={scheduleSubmit}
                submitError={() => {
                  setScheduleSubmit(false);
                }}
                submitSuccess={(schedule) => {
                  setSchedule(schedule);
                  if (scheduleSubmit) {
                    submitForm({
                      ...values,
                      schedule,
                    });
                  }
                  setScheduleSubmit(false);
                }}
                extendedPeriods={extendedPeriods(schedule, startDateSchedulePeriod, endDateSchedulePeriod)}
                setCurrentErrorSchedule={setScheduleIsError}
              />
              <Push size={12} />
              <Panel
                title={() => (
                  <>
                    Ответственные преподаватели <span className="color-gray-dark">{' \u00A0'}</span>
                  </>
                )}
              >
                <TeachersField
                  name="teachers"
                  hasCreateServiceClass
                />
              </Panel>

              <SavePanel
                buttonPrimaryText="Создать группу"
                primaryButtonModifiers={{
                  loading: loadBtn,
                  disabled: !isValid || scheduleIsError,
                }}
                onClickSeconadaryButton={handleCancel}
                onClickPrimaryButton={handleCreate}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ServiceClassDonmCreate;

const getValidationSchema = (currentTrainingGroup: TrainingGroupItemType | null, initialCapacity?: number) => {
  const depsDate: [string, string] = ['trainStartDate', 'trainEndDate'];

  return objectYup().shape(
    {
      serviceId: childrenAssociation,
      name: serviceClassName,
      trainStartDate: docDate
        .nullable()
        .required('Выберите дату начала')
        .test('trainStartDate', '', (value: Date | null | undefined, ctx: any) => {
          if (
            currentTrainingGroup &&
            value &&
            getStartDate(currentTrainingGroup.requestStartDate) > getStartDate(value)
          ) {
            return ctx.createError({
              message: 'Дата начала занятий в группе не может быть меньше, чем дата начала приема заявлений',
            });
          }
          if (value && ctx.from[0]?.value.trainEndDate && value > ctx.from[0]?.value.trainEndDate) {
            return ctx.createError({
              message: 'Дата начала обучения больше даты окончания обучения',
            });
          }

          return true;
        }),
      trainEndDate: validationCheckDate(
        'Выберите дату окончания',
        {
          start: 'trainStartDate',
          end: 'trainEndDate',
        },
        'Дата окончания обучения меньше даты начала обучения',
        'end'
      ),
      capacity: minCapacityValidation(initialCapacity),
      teachers: arrayYup()
        .min(1, 'Добавьте хотя бы одного преподователя')
        .test('test teachers', '', (value) => {
          return (value as TeacherType[]).some((v) => v.isSupervisor);
        })
        .of(
          objectYup().shape({
            name: stringYup().required('Введите ФИО преподавателя'),
          })
        ),
      address: stringYup().when('typeOfAttendanceId', {
        is: 1,
        then: (s) => s.required('Выберите адрес'),
      }),
      trainingGroupId: stringYup().required('Выберите план приема'),
    },
    [depsDate]
  );
};
